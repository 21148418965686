import React from "react";
import { useAuth } from "../../hooks/auth";

import { Container, Content } from "./styles";

import Navbar from "../../components/Navbar/Navbar.js";

const Dashboard: React.FC = () => {
    const { loja } = useAuth();

    return (
        <>
            <Navbar />
            <Container>
                <Content>
                    <h2>Seja bem vindo(a), {loja.nome}</h2>
                </Content>
            </Container>
        </>
    );
};

export default Dashboard;
