/* eslint-disable no-unused-vars */
import React, { useCallback, useRef } from "react";
import { FiUser, FiLock } from "react-icons/fi";

import { Link, useHistory } from "react-router-dom";
import * as Yup from "yup";

import { Form } from "@unform/web";
// eslint-disable-next-line no-unused-vars
import { FormHandles } from "@unform/core";

import { useAuth } from "../../hooks/auth";
import { useToast } from "../../hooks/toast";
import { Background, Container, Content, AnimationContainer } from "./styles";

import InputLogin from "../../components/InputLogin";
import ButtonRoxo from "../../components/ButtonRoxo";

import getValidationErrors from "../../utils/getValidationErrors";

interface SignInFormData {
    username: string;
    cpf: string;
}

const Login: React.FC = () => {
    const formRef = useRef<FormHandles>(null);

    const { signIn } = useAuth();

    const { addToast } = useToast();

    const history = useHistory();

    const handleSubmit = useCallback(
        async (data: SignInFormData) => {
            try {
                formRef.current?.setErrors({});

                const schema = Yup.object().shape({
                    username: Yup.string().required("Username obrigatório"),
                    cpf: Yup.string().required("CPF obrigatório"),
                });

                await schema.validate(data, {
                    // Faz com que o Yup não pare no primeiro erro. Por padrão é true.
                    abortEarly: false,
                });

                await signIn({
                    username: data.username,
                    cpf: data.cpf,
                });

                history.push("/dashboard");
            } catch (err) {
                if (err instanceof Yup.ValidationError) {
                    const errors = getValidationErrors(err);

                    formRef.current?.setErrors(errors);
                }
                if (err instanceof Error) {
                    addToast({
                        type: "error",
                        title: "Erro ao fazer o login",
                        description: JSON.parse(err.message).message,
                    });
                }
            }
        },
        [signIn, addToast, history],
    );

    return (
        <Container>
            <Background />
            <Content>
                <AnimationContainer>
                    <Form ref={formRef} onSubmit={handleSubmit}>
                        <InputLogin
                            icon={FiUser}
                            name="username"
                            placeholder="Username"
                        />

                        <InputLogin
                            icon={FiLock}
                            name="cpf"
                            placeholder="CPF"
                        />

                        <ButtonRoxo type="submit">Entrar</ButtonRoxo>
                    </Form>
                </AnimationContainer>
            </Content>
        </Container>
    );
};

export default Login;
