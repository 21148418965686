/* eslint-disable no-console */
import React from "react";

import {
    Route as ReactDOMRoute,
    // eslint-disable-next-line no-unused-vars
    RouteProps as ReactDOMRouteProps,
    Redirect,
} from "react-router-dom";
import { useAuth } from "../hooks/auth";

interface RouteProps extends ReactDOMRouteProps {
    isPrivate?: boolean;
    component: React.ComponentType;
}

const Route: React.FC<RouteProps> = ({
    isPrivate = false,
    component: Component,
    ...rest
}) => {
    const { loja } = useAuth();

    return (
        <ReactDOMRoute
            {...rest}
            render={({ location }) => {
                // Se a página for privada e o usuário existir.
                if (isPrivate && loja) return <Component />;
                // Se a página for livre e o usuário existir.
                if (!isPrivate && loja) return <Component />;
                // Se a página for privada e o usuário não existir.
                if (isPrivate && !loja) {
                    return (
                        <Redirect
                            to={{
                                pathname: isPrivate ? "/" : "/",
                                state: { from: location },
                            }}
                        />
                    );
                }
                return <Component />;
            }}
        />
    );
};

export default Route;
