import { createGlobalStyle } from "styled-components";

export default createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        outline: 0;
    }
    body {
        background: #FFFFFF;
        color: #FFF;
        -webkit-font-smoothing: antialiased;
    }
    body, input, button {
        font-family: "Nunito";
        font-size: 20px;
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: 300;
    }

    strong {
        font-weight: 600;
    }
    button {
        cursor: pointer;
    }
`;
