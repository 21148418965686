import React, { createContext, useCallback, useState, useContext } from "react";

import api from "../services/api";

interface Link {
    id: string;
    idLoja: string;
    extensao: string;
    mensagem: string;
}

interface Vendedor {
    id: string;
    idLoja: string;
    nome: string;
    celular: string;
    isProximo: boolean;
}

interface Loja {
    id: string;
    nome: string;
    nomeResponsavel: string;
    username: string;
    cpf: string;
    vendedores: Vendedor[];
    links: Link[];
}

interface SignInCredentials {
    username: string;
    cpf: string;
}

interface AuthContextData {
    loja: Loja;
    signIn(credentials: SignInCredentials): Promise<void>;
    signOut(): void;
}

interface AuthState {
    token: string;
    loja: Loja;
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData);

const AuthProvider: React.FC = ({ children }) => {
    const [data, setData] = useState<AuthState>(() => {
        const token = localStorage.getItem("@project:token");
        const loja = localStorage.getItem("@project:loja");

        if (token && loja) {
            api.defaults.headers.authorization = `Bearer ${token}`;
            return {
                token,
                loja: JSON.parse(loja),
            };
        }

        return {} as AuthState;
    });

    const signIn = useCallback(async ({ username, cpf }) => {
        const response = await api
            .post("/session", {
                username,
                cpf,
            })
            .catch(retorno => {
                throw new Error(retorno.request.responseText);
            });

        if (response.data.loja) {
            const { token, loja } = response.data;

            localStorage.setItem("@project:token", token);
            localStorage.setItem("@project:loja", JSON.stringify(loja));

            api.defaults.headers.authorization = `Bearer ${token}`;

            setData({ token, loja });
        }
    }, []);

    const signOut = useCallback(() => {
        localStorage.removeItem("@project:token");
        localStorage.removeItem("@project:loja");

        setData({} as AuthState);
    }, []);

    return (
        <AuthContext.Provider
            value={{
                loja: data.loja,
                signIn,
                signOut,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

function useAuth(): AuthContextData {
    const context = useContext(AuthContext);

    if (!context) {
        throw new Error("useAuth must be used within a AuthProvider");
    }

    return context;
}

export { AuthProvider, useAuth };
