/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";

import { Container, Content } from "./styles";

import Navbar from "../../components/Navbar/Navbar.js";

interface Vendedor {
    id: string;
    idLoja: string;
    nome: string;
    celular: string;
    isProximo: boolean;
}

const Vendedores: React.FC = () => {
    const { loja } = useAuth();

    const [vendedores, setVendedores] = useState<Vendedor[]>([]);

    useEffect(() => {
        api.get(`/vendedores/loja/${loja.id}`).then(response =>
            setVendedores(response.data),
        );
    }, [loja.id]);

    return (
        <>
            <Navbar />
            <Container>
                <Content>
                    <MDBTable bordered borderColor="primary">
                        <MDBTableHead className="text-center">
                            <tr>
                                <th
                                    scope="col"
                                    colSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <strong>VENDEDORES</strong>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    scope="col"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    Vendedor
                                </th>
                                <th
                                    scope="col"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    Celular
                                </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {vendedores.map(vendedor => (
                                <tr>
                                    <td>{vendedor.nome}</td>
                                    <td>{vendedor.celular}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </Content>
            </Container>
        </>
    );
};

export default Vendedores;
