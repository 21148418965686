/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";
import { Content, Container } from "./styles";

import Navbar from "../../components/Navbar/Navbar.js";

interface Link {
    id: string;
    idLoja: string;
    extensao: string;
    mensagem: string;
}

const Links: React.FC = () => {
    const { loja } = useAuth();

    const [links, setLinks] = useState<Link[]>([]);

    useEffect(() => {
        api.get(`/links/${loja.id}`).then(response => setLinks(response.data));
    }, [loja.id]);

    return (
        <div>
            <Navbar />
            <Container>
                <Content>
                    <MDBTable bordered borderColor="primary">
                        <MDBTableHead className="text-center">
                            <tr>
                                <th
                                    scope="col"
                                    colSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <strong>LINKS</strong>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    scope="col"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    Link
                                </th>
                                <th
                                    scope="col"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    Mensagem
                                </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {links.map(link => (
                                <tr>
                                    <td>
                                        {loja.username}.whats.dev/
                                        {link.extensao}
                                    </td>
                                    <td>{link.mensagem}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </Content>
            </Container>
        </div>
    );
};

export default Links;
