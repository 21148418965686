import React, { useState } from 'react';
import * as FaIcons from 'react-icons/fa';
import * as AiIcons from 'react-icons/ai';
import { Link } from 'react-router-dom';
import { useAuth } from "../../hooks/auth";
import './Navbar.css';
import { IconContext } from 'react-icons';

function Navbar() {
    const { signOut } = useAuth();
    const [sidebar, setSidebar] = useState(false);

    const showSidebar = () => setSidebar(!sidebar);

    return (
        <>
            <IconContext.Provider value={{ color: '#fff' }}>
                <div className='navbar'>
                <Link to='#' className='menu-bars'>
                    <FaIcons.FaBars onClick={showSidebar} />
                </Link>
                </div>
                <nav className={sidebar ? 'nav-menu active' : 'nav-menu'}>
                    <ul className='nav-menu-items' onClick={showSidebar}>
                        <li className='navbar-toggle'>
                        <Link to='#' className='menu-bars'>
                            <AiIcons.AiOutlineClose />
                        </Link>
                        </li>
                        <li key='1' className='nav-text'>
                            <Link to="/links">
                                <AiIcons.AiOutlineLink />
                                <span>Links</span>
                            </Link>
                        </li>
                        <li key='2' className='nav-text'>
                            <Link to="/vendedores">
                                <AiIcons.AiOutlineUser />
                                <span>Vendedores</span>
                            </Link>
                        </li>
                        <li key='3' className='nav-text'>
                            <Link to="/acessos">
                                <AiIcons.AiOutlineDatabase />
                                <span>Acessos</span>
                            </Link>
                        </li>
                        <li key='4' className='nav-text'>
                            <Link onClick={signOut}>
                                <AiIcons.AiOutlinePoweroff />
                                <span>Sair</span>
                            </Link>
                        </li>
                    </ul>
                </nav>
            </IconContext.Provider>
        </>
    );
}

export default Navbar;
