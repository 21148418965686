import styled from "styled-components";

export const Container = styled.div``;

export const Content = styled.main`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    color: black;
    padding: 2% 2% 2% 2%;
`;
