/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable no-return-assign */
import React, { useEffect, useState } from "react";
import { MDBTable, MDBTableHead, MDBTableBody } from "mdb-react-ui-kit";
import moment from "moment";
import api from "../../services/api";
import { useAuth } from "../../hooks/auth";

import { Container, Content } from "./styles";

import Navbar from "../../components/Navbar/Navbar.js";

interface Acesso {
    data: Date;
    acessos: number;
}

const Acessos: React.FC = () => {
    const { loja } = useAuth();

    const [acessos, setAcessos] = useState<Acesso[]>([]);

    moment.locale("pt-br");

    useEffect(() => {
        api.get(`/lojas/acessos/${loja.id}`).then(response =>
            setAcessos(response.data),
        );
    }, [loja.id]);

    return (
        <>
            <Navbar />
            <Container>
                <Content>
                    <MDBTable bordered borderColor="primary">
                        <MDBTableHead className="text-center">
                            <tr>
                                <th
                                    scope="col"
                                    colSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    <strong>ACESSOS</strong>
                                </th>
                            </tr>
                            <tr>
                                <th
                                    scope="col"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    Data
                                </th>
                                <th
                                    scope="col"
                                    rowSpan={2}
                                    style={{
                                        verticalAlign: "middle",
                                    }}
                                >
                                    Acessos
                                </th>
                            </tr>
                        </MDBTableHead>
                        <MDBTableBody>
                            {acessos.map(acesso => (
                                <tr style={{ textAlign: "center" }}>
                                    <td>
                                        {moment(acesso.data).format(
                                            "DD/MM/YYYY",
                                        )}
                                    </td>
                                    <td>{acesso.acessos}</td>
                                </tr>
                            ))}
                        </MDBTableBody>
                    </MDBTable>
                </Content>
            </Container>
        </>
    );
};

export default Acessos;
