import React from "react";
import { Switch } from "react-router-dom";

import Route from "./Route";

import Login from "../pages/Login";
import Dashboard from "../pages/Dashboard";
import Links from "../pages/Links";
import Acessos from "../pages/Acessos";
import Vendedores from "../pages/Vendedores";

const Routes: React.FC = () => {
    return (
        <Switch>
            <Route path="/" exact component={Login} />
            <Route path="/login" exact component={Login} />
            <Route path="/dashboard" exact component={Dashboard} isPrivate />
            <Route path="/links" exact component={Links} isPrivate />
            <Route path="/acessos" exact component={Acessos} isPrivate />
            <Route path="/vendedores" exact component={Vendedores} isPrivate />
        </Switch>
    );
};

export default Routes;
